.betting-area-container {
  /* display: flex;
  flex-direction: column; */
  /* width: 90.5%; */
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.betting-container {
  display: flex;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; */
  width: 100%;
  /* min-width: 1400px; */
  /* justify-content: space-evenly; */
  justify-content: center;
  /* justify-content: space-between; */
  /* width: 100%; */
  /* width: 100%; */
}

.complete-container {
  display: flex;
  flex-direction: column;
  /* justify-content:center; */
  width: 100%;
}

.complete-container-top {
  display: flex;
  justify-content: center;
}

