
.slip-body {
  background-color: var(--primary-light-body-color);
  /* padding: 10px 10px 0px 10px; */
  min-height: 150px;
  margin-bottom: 5px;
}

.slip-body-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.slip-body-main {
  padding-right: 10px;
  padding-left: 10px;
}

.win-value {
  color: green;
}

.lose-value {
  color: red;
}

.slip-exit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* text-align: right; */
  width: 25px;
  height: 25px;
  border-radius: 5px;
  /* justify-content: right; */
  /* background-color: white; */
  color: grey;
}

.slip-exit:hover {
  cursor: pointer;
  color: red;
}

.slip-buttons {
  /* margin: 10px; */
}

/* .slip-button {
  margin: 10px;
  font-weight: bold;
} */

#submit-slip {
  background-color: #85bb65;
  color: var(--primary-light-font-color);
  border-radius: 5px;
}

.slip-to-lose {
  height: 20px;
  width: 50px;
  border-radius: 5px;
}

.error {
  border: 1px solid red;
}

.slip-away-team {
  text-align: left;
}

.slip-home-team {
  text-align: left;
}

.slip-money {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 10px;
  /* padding-bottom */
}

.slip-bet-info {
  margin-top: 5px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13.5px;
  height: 25px;
}

.multi-bet-exit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 25px;
  border-radius: 5px;
  color: grey;
}

.multi-bet-exit:hover {
  cursor: pointer;
  color: red;
}

#add-bet-retroactive:hover {
  color: #85bb65;
  cursor: pointer;
}

.success {
  color: #85bb65;
}
