.members-page {
  /* display: flex;
  flex-direction: column;
  width: 100%; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  /* justify-content: center; */
  /* display: flex; */
}

.members-section-top {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-evenly; */
  /* border: 4px darkblue solid; */
  /* width: 100%; */
  /* padding: 20px; */
  /* height: 100%; */
  margin-top: 20px;
  margin-bottom: 20px;
}

/* .members-section-mid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items:center; */
  /* width: 100%; */
  /* padding: 20px; */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  /* height: 100%; */
/* } */

.members-section-mid {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
}
