.league-dropdown2 {
  width: 100%;
  background-color: var(--primary-light-body-color);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  font-size: 14px;
}

.league-dropdown-buttons {
  display: flex;
  /* flex-direction: row; */
  width: 100%;
  height: 35px;
  background-color: var(--primary-light-body-color);
}

.dropdown-button {
  width: 100%;
  height: 35px;
  /* display: flex;
  flex-direction: row; */
  background-color: var(--primary-light-body-color);
  text-align: left;
  padding-left: 30px;
}

.dropdown-button:hover {
  background-color: darkgrey;
  color: white;
}

.league-button {
  width: 100%;
  background-color: var(--primary-light-body-color);
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding-left: 15px;
}
