.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
}

.header-title {
  margin-left: 15px;
  color: var(--primary-dark-font-color);
  font-weight: bold;
  font-size: 28px;
}

.header-links {
  margin-right: 15px;
  color: var(--primary-light-font-color);
}

.header-link {
  list-style-type: none;
  color: green;
  font-weight: bold;
}

.user-button {
  border-radius: 5px;
}