.line-edit-container {
  display: flex;
  flex-direction: column;
  position: relative;
  /* z-index: -1000; */
  /* z-index:1; */
}

.dropdown-buttons-slip {
  /* display: flex; */
  /* top: 100%; */
  /* flex-direction: column; */
  position: absolute;
  border-radius: 5px;
  margin-top: 20px;
  /* z-index:1; */
  z-index: 1000;
}

/* .dropdown-buttons-slip: {
} */

.dropdown-button-slip {
  height: 20px;
  display: flex;
  flex-direction: row;
  /* z-index: 1; */
  /* position: absolute; */
  /* top: 100%; */
  /* flex-direction: column; */
  width: 110px;
  z-index: 1000;

}

.target-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  width: 110px;
  border-radius: 5px;
  z-index: 1000;
  /* margin-bottom: 30px; */
  /* position: relative; */
}

/* .target-button-contents {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */
