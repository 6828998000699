.signup-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* height: auto; */
  height: 89vh;
}

.signup-field {
  background-color: var(--primary-light-body-color);
  height: 30px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 200px;
}

.signup-form {
  /* background-color: lightgray; */
  width: 320px;
  /* padding: 30px; */
  border: 4px solid darkblue;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.signup-form-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--primary-dark-header-color);
  color: var(--primary-light-font-color);
  font-size: 24px;
  font-weight: bold;
  height: 50px;
}

.signup-title {
  display: flex;
  margin-left: 10px;
}

.signup-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  /* padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px; */
  /* margin: 15px; */
}

.signup-names-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signup-button:hover {
  cursor: pointer;
}

#first-name,
#last-name {
  /* width: 150px; */
}

.signup-button {
  width: 150px;
  background-color: var(--secondary-light-background-color);
  color: var(--primary-light-font-color);
  border-radius: 5px;
  /* margin-bottom: 15px; */
}

.signup-button:hover {
  cursor: pointer;
  background-color: darkgreen;
}

.login-portal {
  margin-top: 15px;
}
