.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50px;
  /* position: absolute;
  bottom: 0; */
}

.footer-title {
  color: var(--primary-dark-font-color);
}
