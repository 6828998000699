.login-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* height: auto; */
  height: 89vh;
}

.login-form {
  /* display: flex; */
  height: 300px;
  width: 300px;
  /* position: fixed;
  top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  border-radius: 5px;
  /* padding: 10px; */
  border: 4px darkblue solid;
}

.login-form-body {
  margin-top: 40px;
}

.login-row {
  margin-bottom: 20px;
}

.login-field {
  width: 200px;
  height: 30px;
  padding-left: 5px;
}

.login-button {
  width: 200px;
  height: 30px;
  /* border: 1px solid var(--secondary-light-background-color); */
  border-radius: 5px;
  background-color: var(--secondary-light-background-color);
  color: var(--primary-light-font-color);
}

.login-button:hover {
  cursor: pointer;
  background-color: darkgreen;
}

.login-form-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  font-weight: bold;
  font-size: 24px;
  background-color: var(--primary-dark-header-color);
  color: var(--primary-light-font-color);
  /* margin-bottom: 40px;
  margin-top: 30px; */
}

.login-form-title {
  display: flex;
  margin-left: 10px;
  /* margin-bottom: 40px;
  margin-top: 30px; */
}
