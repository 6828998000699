.dashboard-container {
  width: 90%;
  background-color: var(--primary-light-body-color);
  border: 4px darkblue solid;
  border-radius: 5px;
  height: 350px;
  /* border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; */
  /* background-color: #D0D3D4; */
  /* border-radius: 5px; */
  /* margin-left: 30px;
  margin-top: 30px; */
}

.dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  background-color: var(--primary-dark-header-color);
}

.dashboard-title {
  color: var(--primary-light-font-color);
  font-weight: bold;
  margin-left: 10px;
  font-size: 20px;
}

.dashboard-right {
  color: var(--primary-light-font-color);
  font-weight: bold;
  margin-right: 10px;
  font-size: 20px;
}

.dashboard-body {
  height: 300px;
  /* width: 100%; */
  /* height: 100%; */
  background-color: lightgrey;
  /* overflow-y: auto; */
  /* border-radius: 5px; */
}
