button {
  height: 35px;
  font-weight: bold;
}

/* button:hover {
  cursor: pointer;
} */

.render-team {
  text-align: left;
  font-weight: bold;
  width: 250px;
}

.render-main-body {
  background-color: var(--primary-light-body-color);
  margin-bottom: 10px;
  /* width: 750px; */
  padding: 10px;
  height: 100px;
  border-radius: 5px;
  font-size: 13px;
}

.render-games {
  margin-bottom: 20px;
  margin-left: 30px;
  padding: 10px;
  background-color: var(--primary-light-body-color);
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-wrap: wrap; */
  border: none
}

.render-game {
  display: flex;
  flex-direction: row;
  margin: 10px;
  flex-wrap: wrap;
}

.render-gametime {
  margin-left: 10px;
  font-weight: bold;
}

.render-away-row {
  height: 10px;
}

.render-sub-body {
  margin-left: 150px;
  height: 10px;
}

.render-button {
  /* padding-left: 10px;
  padding-right: 10px; */
  width: 120px;
}

.render-gametime-row {
  padding: 0px;
}
