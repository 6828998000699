body {
  /* height: 100%; */
  height: 100vh;
  /* width: 100%; */
  overflow: auto;
}

:root {
  height: 100vh;
  --primary-dark-header-color: darkblue;
  --primary-light-body-color: #FFFFFF;
  --primary-dark-font-color: darkblue;
  --primary-light-font-color: #FFFFFF;
  --secondary-light-background-color: #85bb65;
  --secondary-light-font-color: #85bb65;
  /* --dark-back-font-color: #fff;

  --secondary-back-color: #EAFAF1;
  --light-back-font-color: #333; */
}

.App {
  text-align: center;
  background-color: #D0D3D4;
  height: 100vh;
  /* align-items: center; */
  /* width: 100%; */
  /* width: 100%; */
  overflow: auto;
}

button:hover {
  cursor: pointer;
}

/* .app-body {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
} */

/* .app-header {
  height: 5vh;
}

.app-body {
  height: 90vh;
}

.app-footer {
  height: 5vh;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
