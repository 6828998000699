.nav-container {
  display: flex;
  flex-direction: column;
  /* flex-direction: row; */
  height: 100%;
  /* height: 500px; */
  min-width: 150px;
  /* margin: 10px; */
  margin: 10px 10px 10px 0px;
  /* padding: 5px; */
  /* min-width: 150px;
  max-width: 250px; */
  /* width: 220px; */
  flex-basis: 275px;
  /* max-width: 350px; */
  /* width: 100%; */
  border: 4px darkblue solid;
  border-radius: 5px;
  /* overflow: scroll; */
  /* width: 240px;
  overflow: scroll;
  overflow-x: hidden; */
}

button {
  border: 1px black solid;
}

button:hover {
  background-color: darkgrey;
  color: white;
}

.nav-sports-container {
  /* overflow: scroll;
  overflow-x: hidden; */
  max-height: 700px;
  overflow: auto;
}

/* .nav-header {
  text-align: left;
} */

.scroll-container {
  /* padding-left: 10px;
  padding-right: 10px; */
  height: 375px;
  overflow: scroll;
  overflow-x: hidden;
}

.nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--primary-dark-header-color);
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
}

.nav-title {
  display: flex;
  margin-left: 10px;
  color: var(--primary-light-font-color);
}

/* .nav-links {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.nav-link {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  color: green;
} */
