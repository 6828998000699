.slip-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 275px;
  /* max-width: 450px; */
  /* width: 450px; */
  flex-basis: 475px;
  /* width: 30%; */
  /* width: 100%; */
  /* width: 450px; */

  /* max-height: 750px; */
  margin: 10px 0px 10px 10px;
  /* margin: 10px; */
  /* padding: 5px; */
  border: 4px solid darkblue;
  border-radius: 5px;
}

.slip-container-body {
  max-height: 590px;
  overflow-y: auto;
}

.slip-container-body-active {
  max-height: 665px;
  overflow-y: auto;
}

.slip-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slip-tab {
  /* display: flex;
  background-color: var(--primary-light-body-color); */
}

.slip-tab:hover {
  background-color: darkgrey;
  color: white;
}

#slip-tab-cart {
  border: none;
  width: 50%;
}

#slip-tab-active {
  border: none;
  width: 50%;
}

.slip-tab-focus {
  background-color: #85bb65;
  color: var(--primary-light-font-color);
  font-weight: bold;
}

.slip-tab-unfocus {
  background-color: #D0D3D4;
}

.empty-slip-container {
  height: 200px;
  background-color: var(--primary-light-body-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#empty-slip-image {
  height: 50px;
  width: 50px;
}

.slip-total-money {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: var(--primary-light-font-color);
}

.slip-summary-section {
  background-color: #63676b;
}

.slip-button {
  width: 110px;
  border-radius: 5px;
}

.slip-button:hover {
  background-color: darkgrey;
}

.slip-title {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-left: 10px;
}

.slip-header {
  background-color: var(--primary-dark-header-color);
  color: var(--primary-light-font-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  height: 50px;
}
